<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
        <span>Аккаунт</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiLockOpenOutline }}
        </v-icon>
        <span>Безопасность</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account></account-settings-account>
      </v-tab-item>
      <v-tab-item>
        <account-settings-security></account-settings-security>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiCheck, mdiClose,
  mdiLockOpenOutline,
} from '@mdi/js'

import AccountSettingsAccount from './account-settings-account.vue'
import AccountSettingsSecurity from './account-settings-security.vue'
import { mapActions } from 'vuex'
import { rules } from '@/helpers/validation'

export default {
  name: 'AccountSettings',
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
  },
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiLockOpenOutline,
    },
    tab: null,
    rules,
  }),
  async mounted() {
    await this.fetch()
  },
  methods: {
    ...mapActions('user/form', ['fetch']),
  },
}
</script>
